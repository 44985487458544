const Images = {
    logo : require("./Assets/logo.png"),
    astronaut : require("./Assets/astronaut.png"),
    leftarrowimg : require("./Assets/leftarrow.png"),
    rightarrowimg : require("./Assets/rightarrow.png"),
    cardimg: require("./Assets/cardimg.png"),


    partner1 : require("./Assets/partner1.png"),
    partner2 : require("./Assets/partner2.png"),

    youtubes : require("./Assets/Youtube.png"),

    team1 : require("./Assets/team1.png"),
    team2 : require("./Assets/team2.png"),
    team3 : require("./Assets/team3.png"),
    team4 : require("./Assets/team4.png"),
    team5 : require("./Assets/team5.png"),
    team6 : require("./Assets/team6.png"),
    team7 : require("./Assets/team7.png"),
    team8 : require("./Assets/team8.png"),
    team9 : require("./Assets/team9.png"),

    media1 : require("./Assets/media1.png"),
    media2 : require("./Assets/media2.png"),
    media3 : require("./Assets/media3.png"),
    media4 : require("./Assets/media4.png"),
    media5 : require("./Assets/media5.png"),
    media6 : require("./Assets/media6.png"),
    media7 : require("./Assets/media7.png"),
    media8 : require("./Assets/media8.png"),
    media9 : require("./Assets/media9.png"),
    media10 : require("./Assets/media10.png"),
    media11 : require("./Assets/media11.png"),
    media12 : require("./Assets/media12.png"),

    builton1 : require("./Assets/builton1.png"),
    builton2 : require("./Assets/builton2.png"),
    builton3 : require("./Assets/builton3.png"),
    builton4 : require("./Assets/builton4.png"),
    builton5 : require("./Assets/builton5.png"),
    builton6 : require("./Assets/builton6.png"),
    builton7 : require("./Assets/builton7.png"),
    builton8 : require("./Assets/builton8.png"),

    footertopimg : require("./Assets/footerborder.png"),
    circle : require("./Assets/circle.png"),
    social1 : require("./Assets/social1.png"),
    social2 : require("./Assets/social2.png"),
    social3 : require("./Assets/social3.png"),
    social4 : require("./Assets/social4.png"),
    discard:require("./Assets/discard.png"),
    tele:require("./Assets/tele.png"),
    twit:require("./Assets/twit.png"),
    dot:require("./Assets/dot.png"),
    shield : require("./Assets/shield.svg").default,
    shelter : require("./Assets/shelter.svg").default,
    shelterhover : require("./Assets/shelterhover.svg").default,
    rocket : require("./Assets/rocket.svg").default,
    rockethover : require("./Assets/rockethover.svg").default,
    target : require("./Assets/target.svg").default,
    targethover : require("./Assets/targethover.svg").default,
    layer : require("./Assets/layer.svg").default,
    layerhover : require("./Assets/layerhover.svg").default,
    transact : require("./Assets/transact.svg").default,
    transacthover : require("./Assets/transacthover.svg").default,
    flag : require("./Assets/flag.svg").default,
    flaghover : require("./Assets/flaghover.svg").default,
    galaxy : require("./Assets/galaxy.svg").default,
    galaxyhover : require("./Assets/galaxyhover.svg").default,
    group : require("./Assets/group.svg").default,
    grouphover : require("./Assets/grouphover.svg").default,
    cloudChakra : require("./Assets/cloud_chakra.png"),

   
}

export default Images
