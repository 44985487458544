import React, { useEffect, useState } from "react";
import { Container, Button, Row, Col, Carousel } from "react-bootstrap";
import Images from "./images";
import Header from "./header";
import Carousel3D from "./carousels";
import Detailmodal from "./Components/detailmodal";
import Marquee from "react-fast-marquee";
import Blogcarousel from "./blogcarousel";
import ReactPlayer from "react-player";
import Footer from "./footer";
import { IoCloseSharp } from "react-icons/io5";
import { blogListApi, newsListApi, promoCMS } from "./API/cms.api";
import config from './config'
import Newssmodal from "./Components/newsmodal";
import { Link } from "react-router-dom";
import Roadmap from "./roadmap";
import Teamprofile from "./teamprofile";


function Landing() {
  const [partnerShip, setpartener] = useState([1, 2, 3, 4]);
  const [bloglist, setBloglist] = useState([]);
  const [newslist, setNewslist] = useState([]);
  const [detmodal, setDetmodal] = useState(false);
  const [promoData, setPromoCMSData] = useState([]);
  const [latest, setLatest] = useState(null);
  const [previewblog, setpreviewblog] = useState(null)
  const [previewnews, setpreviewnews] = useState(null)
  const [chakramouse, setChakramouse] = useState(null)

  const datetransform = (data) => {
    let date = new Date(data)
    const options = { year: 'numeric', month: 'long', day: 'numeric' };
    const formattedDate = date.toLocaleDateString('en-US', options);

    console.log(formattedDate);
    return formattedDate;
  }
  const fetchdata = async () => {
    const news = await newsListApi()
    const blog = await blogListApi()
    const promocms = await promoCMS()
    console.log("🚀 ~ fetchdata ~ promocms:", promocms)

    if (news?.data?.length) {
      setNewslist(news.data)
      setLatest(news?.data[0])
      setpreviewnews(news?.data[0])

    }
    setpartener(promocms?.data?.parnerts.length ? promocms?.data?.parnerts : [])
    setPromoCMSData(promocms?.data)

    if (blog?.data?.length) {
      setpreviewblog(blog?.data[0])
      setBloglist(blog?.data)
    }

  }

  useEffect(() => {
    fetchdata()
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }, []);

  const [newmodal, setNewmodal] = useState(false);
  const [isVisible, setIsVisible] = useState(false);
  const toggleVisibility = () => {
    setIsVisible(!isVisible);
  };

  const rotationDegree = 45;
  const [activeTile, setActiveTile] = useState(0);
  const Tile = ({ index, item }) => {
    return (
      <div
        className={`tile`}
        style={{ "--i": index }}
        onClick={() => { onClickChangeArr(index); setSelected(index) }}
      >
        <img onMouseOver={() => setChakramouse(index)} src={chakramouse == index ? item.imghover : selected == index ? item.imghover : item.img} className={chakramouse ? "img-fluid chakra__tileImg transition" : "img-fluid chakra__tileImg transition"} />
        {/* <img src={selected == index ? item.imghover : item.img} className={chakramouse ?"img-fluid chakra__tileImg transition":"img-fluid chakra__tileImg transition" } /> */}

      </div>
    );
  };
  console.log(activeTile, "eeeeeeee");

  const [chakraList, setChakraList] = useState([
    {
      img: Images.galaxy,
      imghover: Images.galaxyhover,
      title: "Developing Planets & Generating Resources",
      desc: "Building structures on Planet and Asteroid NFTs is the core activity of the economic simulation. Buildings cost tokenised resources to build, and different buildings generate different resources daily. They are key to any strategy to maximise resource production and profits for players, providing a reliable income of tokens on a daily basis. For details of the buildings available please see the chapter Buildings [link to chapter] later in this whitepaper. Planets and Asteroids are NFT collections; players will need to own Planets and / or Asteroids to be able to build buildings on them and develop their worlds.",
    },
    {
      img: Images.layer,
      imghover: Images.layerhover,
      title: "Staking",
      desc: "Staking is another reliable method of generating income for players. There are 17 supported NFT collections that can be staked for passive daily tokenised resources. These collections are outlined in the chapter Crew NFTs [link to the chapter]. GalFi utilises soft-staking, this means the game simply reads your wallet address to verify you own NFTs, and then automatically starts generating daily rewards. The game contract does not actually interact with the NFTs in any way; this means that your NFTs are not “locked” into the game or contract, and your NFTs are always safe as the game operates by reading the wallet address only. GalFi will also release its own Crew NFTs and Specialist NFTs. These Crew and Specialist NFTs will be much more useful for missions and resource generation than the other supported collections. Users can also stake their tokens in the GalFi Nexus directly, receiving a share of 1% of all the funds flowing into the GalFi Nexus wallet from all player purchases of ships and buildings in-game. All 15 tokens can also be staked for rewards in the DEX liquidity pools hosted by GalFi for trading all tokenised resources.",
    },
    {
      img: Images.rocket,
      imghover: Images.rockethover,
      title: "Building Ships",
      desc: "All of the 22 ships in GalFi except for the Micro Shuttle will be built by players. Ships can be used to run missions, as well as traded on the GalFi Nexus NFT Marketplace. Later, in the Colonisation phase of development ships will be used to colonise planets in the Contested Space zone, participate in epic ship to ship battles, and to invade other players’ colonies that are situated in Contested Space.",
    },
    {
      img: Images.flag,
      imghover: Images.flaghover,
      title: "Running Missions",
      desc: "GalFi has 4 types of missions: Mining, Exploration, Social, and Combat. Ships can be loaded with crew NFTs (from the 17 supported collections and the GalFi Crew and Specialist NFTs) and sent on missions. Players can choose to run missions for 30 minutes, 1 hour, 12 hours, 1 day, 3 days, 7 days and 28 days. At the end of the mission the player is rewarded with tokenised resources. The resources earned are based on the type of mission, the NFTs used, and on the ship used: the larger the ship the more NFTs can be sent on the mission, and the more resources that can be earned.",
    },
    {
      img: Images.transact,
      imghover: Images.transacthover,
      title: "Trading",
      desc: "As an economic simulation GalFi has many opportunities for trading. All the game’s currencies and resources can be traded against each other on the in-game DEX. The value of all the tokens will be set by players trading activity in the free markets. For a summary of all the 15 different tokenised resources please see the Tokenomics Chapter [link to chapter here]. GALFI token will be traded on established DEXs on both Ethereum and Polygon at launch. Player built ships, as well as Asteroids and Planets, Crew and Specialist NFTs, and other supported NFTs, can also be traded directly to other players on the GalFi Nexus NFT Marketplace. Well developed Planets and Asteroids will command a premium and be very valuable. Clever players will be able to earn from inefficiencies in the markets as well as selling developed Planets, Ships, and other NFTs: this meta game will be a lucrative activity in itself.",
    },
    {
      img: Images.target,
      imghover: Images.targethover,
      title: "Combat",
      desc: "The Colonisation game update will also introduce PvP combat. Players can engage other players’ fleets in ship to ship combat, and also launch invasions of other players planets situated in Contested Space.",
    },
    {
      img: Images.group,
      imghover: Images.grouphover,
      title: "Colonization",
      desc: "When the Colonisation phase of development launches in Q4 2024 players will be able to colonise planets in the Contested Space star map with Colony Ships. They will then be able to build buildings on these colonised planets to earn resources. However Contested Space lives up to its name, and planets situated in Contested Space can also be invaded by other players.",
    },
    {
      img: Images.shelter,
      imghover: Images.shelterhover,
      title: "Exploration",
      desc: "Exploration missions as described above earn players resources. Also when the Colonisation phase of development is completed in Q4 2024, players can send their ships to explore the Contested Space star map. Many wonders and resources await intrepid explorers, although there is also a risk to lose ships.",
    },
  ]);

  const[medialist,setMedialist] = useState([
    {img:Images.media1},{img:Images.media2},{img:Images.media3},{img:Images.media4},
    {img:Images.media5},{img:Images.media6},{img:Images.media7},{img:Images.media8},
    {img:Images.media9},{img:Images.media10},{img:Images.media11},{img:Images.media12},
  ])

  const[builtonlist,setBuiltonlist] = useState([
    {img:Images.builton1},{img:Images.builton2},{img:Images.builton3},{img:Images.builton4},
    {img:Images.builton5},{img:Images.builton6},{img:Images.builton7},{img:Images.builton8},
  ])

  const [selected, setSelected] = useState(0);

  const [animator, setAnimator] = useState(false)
  const [isReversed, setIsReversed] = useState(false);

  const onClickChangeArr = (i) => {


    setActiveTile(i)
    // const arr = [...chakraList]
    // const sliceArr = arr.slice(i,arr.length);
    // const fullArr = [...sliceArr,...arr.slice(0,i)]
    // console.log("sliceArr",sliceArr,i,arr,fullArr);    
    // setActiveTile(i)

    // setAnimator(true)
    // setIsReversed(false)
    // setTimeout(() => {
    //   setAnimator(false)
    //   setIsReversed(true)
    //   setChakraList(fullArr)
    // }, 300);

  }
  console.log("selected", selected, chakraList[0]);
  return (
    <>
      {detmodal && <Detailmodal onDismiss={() => setDetmodal(false)} data={previewblog} />}
      {newmodal && <Newssmodal onDismiss={() => setNewmodal(false)} data={previewnews} />}
      <div className="">
        <Header />

        <div className="play_galfi h_set h_set1 p_top pb-5" id="playgalfi">
          <Container className="custom_contain">
            <Row className="justify-content-between align-items-baseline">
              <Col lg={7} xl={6} xxl={5} className="pos mt-2 mt-lg-5">
                <div
                  // className="d-flex align-items-end mt-xl-5"
                  className="pos mt-xl-5"
                >
                  <img src={Images.circle} className="img-fluid" />
                  <div className="ps-3">
                    <h2 className="nebula set_top text-white pb-2 pb-sm-4 text-uppercase">
                      “Forge your path to riches amongst the stars”
                    </h2>
                    <h4 className="playbtn cursor text-white mt-1 mt-sm-3 ms-2">
                      Coming Q4 2024
                    </h4>
                  </div>
                </div>
              </Col>
              <Col lg={5} xl={5} xxl={4} className="galfi_p mt-5 mt-lg-0">
                <p className="text-white lh">
                  GalFi (Galactic Finance) is a tokenised sci fi strategy game
                  and economic simulation on a galactic scale. GalFi is the
                  world’s first science fiction themed “4X” play-to-earn game
                  involving combat, exploration, mining, developing planets,
                  base building, token farming, and trading. All resources such
                  as minerals, and structures such as ships and buildings are
                  mined and created by the players themselves.
                  <br></br>
                  <br></br>
                  GalFi provides intuitive gameplay intertwined with deep
                  strategy, enabling absolute beginners and crypto veterans to
                  earn real crypto exploring, mining, trading, building, and
                  fighting in a vast galaxy of limitless possibilities.
                </p>
              </Col>
            </Row>
          </Container>
        </div>


        <div className="about_galfi galfi_chakraSec pos  py-5">
          <h3 className="px-4 px-xl-3 px-xxl-5 nebula text-white text-center text-uppercase pt-5 pb-4">GalFi - Galactic Finance: Game Introduction and Lore Video Trailer</h3>
          <div className="row pt-3">
            <div className="col-10 col-sm-10 col-md-10 col-lg-7 col-xl-6 col-xxl-5 mx-auto">
              <ReactPlayer width="100%" height="350px" controls={true} playing={true} url='https://www.youtube.com/watch?v=f7xSCWQ6Uck' loop />
            </div>
          </div>
        </div>

        <div className="about_galfi galfi_chakraSec pos h_set py-5" id="aboutgalfi">


          <Container className="custom_contain px-0 px-sm-2 py-0 py-sm-3 h-100">
            <h1 className="nebula text-white text-center pt-4 pb-1 pb-sm-5 pb-sm-2 text-uppercase big_font">
              Introducing Galfi
            </h1>
            <Row className="align-items-center h-100 wraprev">
              <Col xs={12} xl={4}>
                <div className="planets_back px-3 px-md-5 px-lg-5 px-xl-4 px-xxl-4  py-4">
                  <img src={chakraList[selected]?.imghover || chakraList[0].imghover} className="img-fluid mb-2" />
                  <h3 className="text-white nebula  mb-4 text-uppercase">
                    {chakraList[selected]?.title || chakraList[0].title}
                  </h3>
                  <p className="text-white chakra__descript">
                    {chakraList[selected]?.desc || chakraList[0].desc}
                  </p>
                </div>
              </Col>
              <Col xs={12} xl={8} className="h-100 px-0 px-sm-2">
                <div className="whole__chakra h-100 d-flex justify-content-center align-items-center position-relative">
                  {/* <p className="m-0 chakra__blueTitle"> {selected?.title || chakraList[0].title}</p> */}
                  <div className="chakra__shieldHolder position-relative">
                    <img src={Images.shield} className="chakra__shield" />
                    {chakraList.map((item, index) => (
                      <p className={`m-0 alltext ${(chakramouse == index || selected == index) && "active"} point${index}`}>{item.title}
                      </p>
                      // <p className={`m-0 alltext ${(selected == index)  && "active"} point${index}`}>{item.title}
                      // </p> 
                    ))}
                    {console.log(selected, "sadasdasd")}
                    {/* <div className={`border__chakra position-relative chakra${activeTile}`}> */}
                    <div className={`border__chakra position-relative chakra`}>
                      {chakraList.map((item, index) => (
                        <Tile key={index} index={index} item={item} />
                      ))}
                      <img src={Images.cloudChakra} className="cloud__chakra" />
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
        </div>

        <div className="lore h_set pos" id="lore">
          <Container className="custom_contain">
            <Row className="align-items-center">
              <Col lg={6} xl={6} xxl={7} className="text-center text-lg-end">
                <img src={Images.astronaut} className="img-fluid astronaut" />
              </Col>
              <Col lg={6} xl={6} xxl={5} className="py-5 py-lg-4 py-xl-5 pt-xl-0">
                <div className="ms-xxl-4">
                  <h1 className="nebula text-white text-uppercase">Lore</h1>
                  <p className="text-white mt-3 lh">
                    In the far future humanity had inexorably spread across the
                    cosmos. This was a time of utopia and peace, trillions lived
                    in paradise on a million worlds . There was no war: everyone
                    lived for a thousand Earth years and pursued their highest
                    calling. Beautiful works of art were created, indeed whole
                    worlds were created as art, and humans were on the verge of
                    discovering the secrets of existence itself. Sentient robots
                    and uplifted apes, wolves, dinosaurs and monkeys did
                    humanity’s bidding.
                    <br></br> <br></br>
                    Then came the great collapse. No one was sure how it started,
                    but everyone saw how it ended. All of humanity’s advanced tech
                    became useless in an instant. Trillions perished from famine,
                    disease, and the wars that erupted over the remaining
                    rudimentary resources of each world.
                    <br></br> <br></br>A secretive group known as The Guardians
                    had been protecting highly advanced AI tech for thousands of
                    years. Many gave their lives for their sacred mission. Like a
                    phoenix rising from the ashes, to try and restore order in the
                    galaxy The Guardians created the GalFi Nexus: a
                    hyperintelligent AI to oversee all technology, all trade, and
                    all resource distribution in the galaxy. Under the ever
                    watchful and omniscient GalFi Nexus AI, humanity and their
                    uplifted ape brethren could begin again on their quest to
                    regain their former glory. Will you help them?
                  </p>
                </div>
              </Col>
            </Row>
          </Container>
        </div>

        <div className="gallery pos py-5" id="gallery">
          <h4 className="nebula text-white text-center text-uppercase pt-5 pb-4 big_font">
            Gallery
          </h4>
          <div lassName="h_set">
            <Carousel3D data={promoData} />
          </div>
          {/* <h3 className="px-4 px-xl-3 px-xxl-5 nebula text-white text-center text-uppercase pt-5 pb-4">GalFi - Galactic Finance: Game Introduction and Lore Video Trailer</h3>
          <div className="row pt-3">
            <div className="col-10 col-sm-10 col-md-10 col-lg-7 col-xl-6 col-xxl-5 mx-auto">
              <ReactPlayer width="100%" height="350px" controls={true} playing={true} url='https://www.youtube.com/watch?v=f7xSCWQ6Uck' loop />
            </div>
          </div> */}
        </div>

        <div className="blog pos pt-5 pb-3" id="blog">
          <Container className="custom_contain py-3">
            <h1 className="nebula text-white text-center pt-2 pb-1 pb-sm-3 text-uppercase big_font">
              Blog
            </h1>
            <Row className="justify-content-between">
              <Col lg={7} xl={6} xxl={6}>
                {/* <h2 className="text-white nebula mb-4 text-uppercase">Latest Blog</h2> */}
                {previewblog ?
                  <Link to={`/blog/${previewblog.url}`}>
                    <div
                      className="blogcardback pos cursor px-3 py-3"
                    // onClick={() => setDetmodal(true)}
                    >
                      <div className="pathcut">
                        <img src={previewblog?.image} alt='blog image broken' className="img-fluid blogimg" />
                      </div>
                      <h4 className="text-white blogtitle fw-600 ps-3 pe-5">
                        {previewblog?.heading}
                      </h4>
                      <div className="dateimg d-flex align-items-center gap-1 px-3">

                        <p className="mb-0 text-white">
                          {/* <span className="ms-1">{datetransform(previewblog?.createdAt)}</span> */}

                        </p>
                      </div>
                    </div>
                  </Link> :
                  <><h3 className="no_data nebula text-uppercase">No Blogs Yet</h3></>}
              </Col>
              <Col
                lg={5}
                xl={6}
                xxl={5}
                className="blog_height py-2 mt-5 mt-lg-0"
              >
                {/* <h2 className="text-white nebula mb-4 text-uppercase ms-2">Blogs</h2> */}
                <Row>
                  {bloglist.length > 0 ?
                    bloglist.slice(0, 4).map((e, i) => (
                      <Col sm={10} md={6} lg={12} className="mx-auto">
                        <Link to={`/blog/${e.url}`}>
                          <div className="simplecard pos px-2 py-2 mb-3"
                          //  onClick={() => setpreviewblog(e)}
                          >
                            <div className="pathcuts">
                              {e.image && <img
                                src={e.image}
                                className="img-fluid blogimg1"
                              />}
                            </div>
                            <h5 className="text-white blogtitle fw-600 ps-3 pe-5">
                              {e?.heading?.slice(0, 48) + '...'}
                            </h5>
                            <div className="dateimg d-flex align-items-center gap-1 px-3">

                              <p className="mb-0 text-white">
                                {console.log('xxxxx', e)}
                                <span className="ms-1">{datetransform(e?.createdAt)}</span>
                              </p>
                            </div>
                          </div>
                        </Link>
                      </Col>
                    )) :
                    <><h3 className="no_data b_data nebula text-uppercase">No Blogs Yet</h3></>}
                </Row>
              </Col>
            </Row>
            {/* <div className="text-center pt-4">
              <Link to="/blogs"><button className="btn viewmore">View More</button></Link>
            </div> */}
            {/* <div className="pt-4 pb-4">
              
              <Blogcarousel />
            </div>
            <div className="pt-5 pb-5">
              <Marquee speed={50} direction="left">
                {partnerShip.map((e, i) => (
                  <div className="marquee_whole">
                    <img
                      src={e.image} alt={e.companyName}
                      className="img-fluid partnerimages"
                    />

                  </div>
                ))}
              </Marquee>
            </div> */}
          </Container>
        </div>

        <div className="blog pos pt-3 pb-5" id="blogs">
          <Container className="custom_contain py-3">
            <div className="pb-4">
              <Blogcarousel />
            </div>
            <div className="text-center pt-3">
              <Link to="/blogs"><button className="btn viewmore">View More</button></Link>
            </div>
            <div className="pt-5 pb-5">
              <Marquee speed={50} direction="left">
                {partnerShip.map((e, i) => (
                  <div className="marquee_whole">
                    <img
                      src={e.image} alt={e.companyName}
                      className="img-fluid partnerimages"
                    />
                  </div>
                ))}
              </Marquee>
            </div>
          </Container>
        </div>

        <div className="media bot_color pos pt-5 pb-5" id="media">
          <Container className="custom_contain pt-3">
            <h1 className="nebula text-white text-center pt-2 pb-1 pb-sm-3 text-uppercase big_font">
              Media
            </h1>
            <Row className="mt-2 mb-5">
              {medialist.map((e,i)=>
              <Col xs={6} sm={4} md={4} lg={4} xl={3} xxl={3} className="mt-4 text-center">
                <div>
                  <img src={e.img} alt="medialogo" className="img-fluid medialogo" />
                </div>
              </Col>)}
            </Row>


            <Row className="justify-content-between align-items-center mb-4">
              <Col lg={7} xl={8} xxl={8}>
                {/* <h2 className="text-white nebula mb-4 text-uppercase">Latest</h2> */}
                <h2 className="text-white nebula mb-4 text-uppercase">Media</h2>
                <div className="h_under pos">
                  {console.log("previewnews?.videoxxx", latest?.video)}

                  {latest ?
                    <>
                      {latest?.video == 'true' ? <ReactPlayer
                        className="react-player"
                        width="100%"
                        height="500px"
                        url={`${config.CDN}${latest?.video}`}
                        controls={true}
                      /> : <img src={latest?.image} className="img-fluid newslatest_img" alt="imglist" />}
                      <h3 className="text-white mt-4 mb-4">
                        {latest?.heading}
                      </h3>
                      <p className="text-white">
                        <div className="des_para" dangerouslySetInnerHTML={{ __html: latest?.description }}></div>
                      </p>
                    </> :
                    <><h3 className="no_data b_data nebula text-uppercase">No News Yet</h3></>}
                  {/* <div style={{ display: isVisible ? "block" : "none" }}>
                  <div className="newsdepth px-4 py-4">
                    <div className="mb-4">
                      <IoCloseSharp
                        className="closebtn cursor"
                        onClick={() => setIsVisible(false)}
                      />
                    </div>
                    <img src={Images.newsimg} className="img-fluid w-100" />
                    <h3 className="text-white mt-3">
                      GalFi has a diverse range of key gameplay loops and
                      activities:{" "}
                    </h3>
                    <p className="text-white">
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                      Curabitur elementum quam non ipsum tincidunt, at mattis
                      ligula tincidunt. Nulla finibus blandit fermentum. Proin
                      tincidunt hendrerit sapien, a mollis leo tincidunt
                      placerat. Nam suscipit ligula elit, eu mollis felis
                      malesuada iaculis. Nulla ultricies ex eget nisl
                      pellentesque posuere. Donec finibus cursus urna eget
                      convallis. Morbi vel libero quis diam ultricies lacinia.
                      Nullam id luctus dolor, ut pharetra enim. Praesent vel est
                      massa.
                      <br></br>
                      <br></br>
                      Pellentesque lorem quam, vulputate at justo sed, lobortis
                      facilisis nulla. Sed lobortis vestibulum tortor, quis
                      vestibulum nunc bibendum ac. Quisque in risus bibendum,
                      imperdiet magna non, rhoncus ipsum. Etiam non ligula
                      tempus, maximus ex quis, viverra lorem. Sed ullamcorper
                      finibus leo et efficitur. Aenean facilisis placerat ante
                      eget feugiat. Curabitur vel pellentesque elit, eget
                      consequat augue. Aliquam tempor est et velit volutpat
                      posuere. Cras aliquet porta arcu, eu eleifend magna
                      sollicitudin imperdiet. Aliquam vitae mauris euismod,
                      facilisis arcu vitae, semper neque. Fusce placerat aliquet
                      eros vitae consectetur. Fusce eu elit porttitor, imperdiet
                      dolor id, pellentesque tellus. Phasellus pellentesque
                      fringilla leo, sit amet tristique lectus commodo a. Aenean
                      facilisis sapien mattis maximus rhoncus. Class aptent
                      taciti sociosqu ad litora torquent per conubia nostra, per
                      inceptos himenaeos.
                      <br></br>
                      <br></br>
                      Nunc eget leo sed nunc egestas ornare. Suspendisse
                      efficitur ultrices commodo. Nullam interdum ex et aliquet
                      scelerisque. Aenean elit mi, tristique ut lectus non,
                      convallis pretium lacus. Donec in blandit orci. Morbi in
                      ipsum dictum, eleifend turpis sed, condimentum erat.
                      Pellentesque et facilisis tortor. Suspendisse cursus
                      placerat tellus, et tincidunt eros accumsan sit amet.
                      Nulla feugiat dapibus velit, quis posuere mi tempor sed.
                      <br></br>
                      <br></br>
                      Morbi cursus dolor eros. In mattis erat ac metus pretium
                      eleifend. Sed vitae turpis eu odio fringilla sollicitudin.
                      Pellentesque ut eleifend elit. Nullam lobortis varius
                      tortor, ut consectetur risus dictum eget. Donec nec magna
                      lacinia, lacinia diam sed, lobortis est. Duis accumsan
                      nibh sed augue tempor, non bibendum nisi tempus. Nam ut
                      justo augue. Donec eleifend elementum nisi, quis tincidunt
                      ex egestas placerat. Aenean vitae ultricies lorem. Duis
                      mollis finibus mi. Fusce id rutrum neque. Sed et diam a
                      lacus luctus aliquam sit amet vel magna.
                    </p>
                  </div>
                </div> */}
                </div>
              </Col>
              <Col lg={5} xl={4} xxl={3} className="h_under mt-5 mt-lg-0">
                <h2 className="text-white nebula mb-4 text-uppercase">News</h2>
                <Row>
                  {newslist.length > 0 ?
                    <>
                      {newslist && newslist.length > 0 && newslist.slice(0, 4).map((e, i) => (
                        <Col sm={6} md={6} lg={12}>
                          <Link to={`/news/${e.url}`}>
                            <div
                              className="news mb-3 pos cursor"
                              onClick={() => {
                                toggleVisibility()
                                setpreviewnews(e)
                                // setNewmodal(true)
                              }}
                            // onClick={toggleVisibility}
                            // onClick={()=>}
                            >
                              {console.log('bloglistx', e)}
                              {e?.image && <img
                                src={e.image}
                                className="img-fluid newsimg"
                              />}
                              <p className="text-white new_text mx-1">
                                {/* <div contentEditable='true' dangerouslySetInnerHTML={{ __html: e?.heading }}> </div> */}
                                {e?.heading?.slice(0, 48) + "..."}
                              </p>
                            </div>
                          </Link>
                        </Col>
                      ))}
                    </> :
                    <><h3 className="no_data b_data nebula text-uppercase">No News Yet</h3></>}
                </Row>
              </Col>
            </Row>
            <div className="text-center pt-2">
              <Link to="/news"><button className="btn viewmore">View More</button></Link>
            </div>
          </Container>
        </div>

        <div className="roadmapsection pos pt-4 pb-4" id="roadmaps">
          <Container className="custom_contain pt-2">
            <Roadmap />
            {/* <Footer /> */}
          </Container>
        </div>


        <div className="roadmapsection builtons pos pt-4" id="team">
          <Container className="custom_contain pt-2">
            <Teamprofile />
            {/* <Footer /> */}
          </Container>
        </div>


        <div className="roadmapsection builtons pos pt-4" id="builton">
          <Container className="custom_contain pt-2">
          <h1 className="nebula text-white text-center pt-2 pb-1 pb-sm-3 text-uppercase big_font">
              Built On
            </h1>
            <Row className="mt-2 mb-5">
              {builtonlist.map((e,i)=>
              <Col xs={6} sm={4} md={4} lg={4} xl={3} xxl={3} className="mt-4 text-center">
                <div>
                  <img src={e.img} alt="medialogo" className="img-fluid medialogo" />
                </div>
              </Col>)}
            </Row>
            <Footer />
          </Container>
        </div>

      </div>
    </>
  );
}

export default Landing;
