import React, { useEffect, useState } from "react";
import Images from "./images";
import { Link } from "react-router-dom";
import { FaLinkedin } from "react-icons/fa";


function Teamprofile() {

  const [teamlist, setTeamlist] = useState([
    { img: Images.team1, name: "Wayne Barker", role: "Chief Executive Officer", linkedin: "https://www.linkedin.com/in/wayne-barker-14405b152/" },
    { img: Images.team2, name: "Fraser Pope", role: "Chief of Operations" },
    { img: Images.team3, name: "Alex Thurston", role: "Head of Business Development", linkedin: "https://www.linkedin.com/in/alex-thurston-b08765a4/" },
    { img: Images.team4, name: "Carina Gerelli", role: "Technology Advisor" },
    { img: Images.team5, name: "Daniel Wood", role: "Communications Manager", linkedin: "https://www.linkedin.com/in/daniel-wood/" },
    { img: Images.team6, name: "Matthew Macutkiewiczr", role: "Game Developer", linkedin:"https://www.linkedin.com/in/mateusz-macutkiewicz-%F0%9F%9A%80-0422a72a0/" },
    { img: Images.team7, name: "David Thaddeus", role: "Head Community Manager", linkedin: "https://www.linkedin.com/in/david-thaddeus-9bb915244" },
    // {img:Images.team8,name:"Delman",role:"Community Manager and Developer"},
    { img: Images.team8, name: "Mateusz Rutkowski", role: "Community Manager and Developer", linkedin: "https://www.linkedin.com/in/mateusz-rutkowski-7862851b4/" },
    { img: Images.team9, name: "Ahsan Ayaz", role: "NFT Expert and Project Advisor", linkedin: "https://www.linkedin.com/in/ahsanayaz113/" },
  ])

  return (

    <div className="pb-3">
      <h1 className="nebula text-white text-center text-uppercase pt-5 pb-4 big_font">
        Team Profiles
      </h1>
      <div className="row">
        {teamlist.map((e, i) =>
          <div class="col-12 col-sm-10 col-md-6 col-lg-4 col-xl-4  col-xxl-3 teamlisted  mt-4 mx-auto">

            <div class="our-team">
              <div class="picture">
                <img class="img-fluid" src={e.img} />
              </div>
              <div class="team-content">
                <h3 class="name text-white">{e.name}</h3>
                <h4 class="title role">{e.role}</h4>
              </div>
              <div className="d-flex justify-content-center linked_hset">
                {e.linkedin &&
                <Link to={e.linkedin} className="socialicons" target="_blank"><FaLinkedin /></Link>}
              </div>
            </div>
          </div>)}
      </div>

    </div>




  )
}

export default Teamprofile