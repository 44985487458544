import React, { useEffect, useState } from "react";
import { Button, Container, Nav, Navbar, Offcanvas, Dropdown } from 'react-bootstrap';
import { NavLink } from 'react-router-dom';
import Images from "./images";
import { Link } from 'react-scroll';
import { sociallistApi } from "./API/cms.api";
import config from './config';
import NavDropdown from 'react-bootstrap/NavDropdown';
import { HashLink } from 'react-router-hash-link';

function Header() {

    const [menuOpen, setMenuOpen] = useState(false);
    const [social, SetSocial] = useState(null);


    const toggleMenu = () => {
        const getWindowWidth = window.innerWidth
        console.log("windn", getWindowWidth, menuOpen)
        if (getWindowWidth < 992) {

            setMenuOpen(false)
        } else { setMenuOpen(false) }
    };

    const [scrolled, setScrolled] = useState(false);

    useEffect(() => {
        fetchSocial()
        const handleScroll = () => {
            if (window.scrollY > 50) {
                setScrolled(true);
            } else {
                setScrolled(false);
            }
        };

        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    const fetchSocial = async () => {
        const socail = await sociallistApi()
        console.log("🚀 ~ fetchSocial ~ socail:", socail)
        if (socail.status) SetSocial(socail.data);

    }

    function openPdfInNewTab() {
        var pdfUrl = 'https://dmywb5hxgl1qh.cloudfront.net/pdf/galfiwhitepapers.pdf';
        var htmlContent = `
            <!DOCTYPE html>
            <html lang="en">
            <head>
                <meta charset="UTF-8">
                <meta name="viewport" content="width=device-width, initial-scale=1.0">
                <title>View PDF</title>
                <style>
                    body, html {
                        margin: 0;
                        height: 100%;
                        overflow: hidden;
                    }
                    iframe {
                        position: absolute;
                        top: 0;
                        left: 0;
                        width: 100%;
                        height: 100%;
                        border: none;
                    }
                </style>
            </head>
            <body>
                <iframe src="${pdfUrl}"></iframe>
            </body>
            </html>
        `;
        var newTab = window.open();
        newTab.document.open();
        newTab.document.write(htmlContent);
        newTab.document.close();
    }
    return (

        <>
            <div className={scrolled ? 'header scrolled' : 'header'}>
                <Navbar key="xl" expand="xl" >
                    <Container className="custom_contain head_contain px-xl-1 px-xxl-0">
                        <Navbar.Brand><HashLink to="/"><img src={Images.logo} className='img-fluid logo' alt='images' /> </HashLink></Navbar.Brand>
                        <Navbar.Toggle aria-controls={`offcanvasNavbar-expand-lg`} onClick={() => setMenuOpen(!menuOpen)} />
                        <Navbar.Offcanvas
                            id={`offcanvasNavbar-expand-xl`}
                            aria-labelledby={`offcanvasNavbarLabel-expand-xl`}
                            placement="end"
                            restoreFocus={false}
                            show={menuOpen}
                            onHide={() => setMenuOpen(!menuOpen)}
                        >
                            <Offcanvas.Header closeButton>
                                <Offcanvas.Title id={`offcanvasNavbarLabel-expand-xl`}>
                                    <HashLink to="/" onClick={() => toggleMenu()}><img src={Images.logo} className='img-fluid smalllogo' alt='images' /></HashLink>                            </Offcanvas.Title>
                            </Offcanvas.Header>
                            <Offcanvas.Body>
                                <Nav className="ms-auto align-items-center heading m_top">
                                    <HashLink to="/#playgalfi" smooth={true} duration={500} activeClass="active" spy={true} activeClassName="active" onClick={() => toggleMenu()}> Play GalFi</HashLink>
                                    <HashLink to="/#aboutgalfi" smooth={true} duration={500} activeClass="active" spy={true} activeClassName="active" onClick={() => toggleMenu()}> About</HashLink>
                                    {/* <Link to="section3" smooth={true} duration={500} activeClass="active" spy={true} activeClassName="active" onClick={() => toggleMenu()}> Whitepaper</Link> */}
                                    {/* <a  href={`${config.CDN}galfiwhitepaper.pdf`}  target="_blank" onClick={() => toggleMenu()}>Whitepaper</a> */}
                                    {/* <NavDropdown title="Whitepaper" id="collapsible-nav-dropdown">
                                        <NavDropdown.Item  href={`https://drive.google.com/file/d/1Pp-9ekAPAIPT_QhGkyg3iQKSFRxMmFNX/view`} target="_blank">PDF</NavDropdown.Item>
                                        <NavDropdown.Item href={social?.gitbook} target="_blank">
                                        GitBook
                                        </NavDropdown.Item>
                                      
                                    </NavDropdown> */}
                                   
                                    <Dropdown className="text-center text-xl-start mobdrops">
                                        <Dropdown.Toggle className="btn_toggle" variant="success" id="dropdown-basic">
                                            Whitepaper
                                        </Dropdown.Toggle>
                                        <Dropdown.Menu className="drop1" >
                                            <Dropdown.Item href={`https://drive.google.com/file/d/1Pp-9ekAPAIPT_QhGkyg3iQKSFRxMmFNX/view`} target="_blank">PDF</Dropdown.Item>
                                            <Dropdown.Item href={social?.gitbook} target="_blank">GitBook</Dropdown.Item>
                                        </Dropdown.Menu>
                                    </Dropdown>
                                    {/* <HashLink to="/#lore" smooth={true} duration={500} activeClass="active" spy={true} activeClassName="active" onClick={() => toggleMenu()}> Lore</HashLink> */}
                                    <HashLink to="/#gallery" smooth={true} duration={500} activeClass="active" spy={true} activeClassName="active" onClick={() => toggleMenu()}> Gallery</HashLink>
                                    {/* <Link to="section6" smooth={true} duration={500} activeClass="active" spy={true} activeClassName="active" onClick={() => toggleMenu()}> Socials</Link> */}
                                    {/* <NavDropdown title="Socials" id="collapsible-nav-dropdown">
                                        <NavDropdown.Item  href={social?.discord} target="_blank" ><img src={Images.discard} className="me-1" /> Discord</NavDropdown.Item>
                                        <NavDropdown.Item href={social?.telegram} target="_blank">
                                        <img src={Images.tele} className="me-1" />
                                        Telegram
                                        </NavDropdown.Item>
                                        <NavDropdown.Item  href={social?.twitter} target="_blank" > <img src={Images.twit} className="me-1" /> Twitter</NavDropdown.Item>
                                        <NavDropdown.Item  href={social?.medium} target="_blank" ><img src={Images.dot} className="me-1" /> Medium</NavDropdown.Item>                                     
                                    </NavDropdown> */}
                                    <Dropdown className="text-center text-xl-start mobdrops">
                                        <Dropdown.Toggle className="btn_toggle" variant="success" id="dropdown-basic">
                                            Socials
                                        </Dropdown.Toggle>
                                        <Dropdown.Menu className="drop1">
                                            <Dropdown.Item href={social?.discord} target="_blank" >
                                                {console.log("Socialszzz", social)}
                                                <img src={Images.discard} className="me-1" /> Discord
                                            </Dropdown.Item>
                                            <Dropdown.Item href={social?.telegram} target="_blank">
                                                <img src={Images.tele} className="me-1" />
                                                Telegram
                                            </Dropdown.Item>
                                            <Dropdown.Item href={social?.twitter} target="_blank" >
                                                <img src={Images.twit} className="me-1" /> Twitter                                         
                                            </Dropdown.Item>

                                            <Dropdown.Item href={social?.medium} target="_blank" >                                      
                                                <img src={Images.dot} className="me-1" /> Medium
                                            </Dropdown.Item>

                                            <Dropdown.Item href="https://www.youtube.com/watch?v=f7xSCWQ6Uck" target="_blank">                                      
                                                <img src={Images.youtubes} className="me-1" /> Youtube
                                            </Dropdown.Item>
                                        </Dropdown.Menu>
                                    </Dropdown> 
                                    {/* <a href={social?.medium} target="_blank" onClick={() => toggleMenu()}> Blog</a> */}
                                    <HashLink to="/#blog" smooth={true} duration={500} activeClass="active" spy={true} activeClassName="active" onClick={() => toggleMenu()}> Blog</HashLink>

                                    {/* <Link to="section7" smooth={true} duration={500} activeClass="active" spy={true} activeClassName="active" onClick={() => toggleMenu()}> Blog</Link> */}
                                    <HashLink to="/#media" smooth={true} duration={500} activeClass="active" spy={true} activeClassName="active" onClick={() => toggleMenu()}> Media</HashLink>
                                    <HashLink to="/#roadmaps" smooth={true} duration={500} activeClass="active" spy={true} activeClassName="active" onClick={() => toggleMenu()}> Roadmap</HashLink>
                                    <HashLink to="/#team" smooth={true} duration={500} activeClass="active" spy={true} activeClassName="active" onClick={() => toggleMenu()}> Team</HashLink>
                                    <HashLink to="/#builton" smooth={true} duration={500} activeClass="active" spy={true} activeClassName="active" onClick={() => toggleMenu()}> Built On</HashLink>
                                </Nav>
                            </Offcanvas.Body>
                        </Navbar.Offcanvas>
                    </Container >
                </Navbar >
            </div>
        </>
    )
}

export default Header;